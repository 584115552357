import React from "react";
import IsoBanner from "./banner";
import LastIsoSection from "./lastSection";
import MidIsoSection from "./middleSection";

const IsoStandard = () => {
  return (
    <>
      <IsoBanner />
      <MidIsoSection />
      <LastIsoSection />
    </>
  );
};

export default IsoStandard;
