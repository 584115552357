import React from "react";
import ATA from "assets/images/new-homepage-assets/accreditation icons/ata.png";
import CLUTCH from "assets/images/new-homepage-assets/accreditation icons/clutch.png";
import USCIS from "assets/images/new-homepage-assets/accreditation icons/uscis.svg";
import { Link } from "gatsby";
import CertOne from "assets/iso-standards/iso_9001.webp";
import CertTwo from "assets/iso-standards/iso_17100.webp";
import CertThree from "assets/iso-standards/iso_18587.webp";
import Check from "assets/iso-standards/check.svg";

const LastIsoSection = () => {
  return (
    <div className="w-11/12 sm:w-9/12 mx-auto py-14">
      <a
        target="_blank"
        href="https://skins.tomedes.com/S3UploadedFiles/1681812516p0942804674_FFPDF-English-108179.pdf"
      >
        <img src={CertOne} alt="" className="sm:hidden inline-block" />
      </a>
      <a
        target="_blank"
        href="https://skins.tomedes.com/S3UploadedFiles/1680501573ISO_17065.pdf"
      >
        <img src={CertTwo} alt="" className="sm:hidden inline-block" />
      </a>
      <a
        target="_blank"
        href="https://skins.tomedes.com/S3UploadedFiles/1680501594ISO_18587.pdf"
      >
        <img src={CertThree} alt="" className="sm:hidden inline-block" />
      </a>

      <ul className="flex sm:flex-row flex-col justify-around mx-auto items-center relative sm:pt-0 pt-8">
        <li className="logos flex flex-col items-center w-[175px]">
          <img src={ATA} alt="" className="lg:pb-12" />
          <div className="  lg:absolute lg:bottom-0">
            <p className="text-[16px] font-medium text-[#313E5A] pt-4  text-center ">
              ATA Corporate
            </p>
            <p className="text-[16px] font-medium text-[#313E5A] text-center ">
              Member Number 272444
            </p>
          </div>
        </li>

        <li className="logos flex flex-col items-center w-[200px] pt-6 lg:pt-0">
          <a
            href="https://clutch.co/translation/leaders-matrix"
            target="_blank"
          >
            <img src={CLUTCH} alt="" className="flex justify-center mx-auto" />
            <div className="">
              <p className="text-[16px] font-medium text-[#313E5A] pt-4  text-center">
                Clutch Top Global
              </p>
              <p className="text-[16px] font-medium text-[#313E5A] text-center">
                Translation Services 2021{" "}
              </p>
            </div>
          </a>
        </li>
        <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0">
          <Link
            to="/uscis-translation-services"
            className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0"
          >
            <img src={USCIS} alt="" className="lg:pb-12 pb-4" />
            <div className="lg:absolute lg:bottom-0">
              <p className="text-[16px] font-medium text-[#313E5A]  text-center">
                100% guaranteed
              </p>
              <p className="text-[16px] font-medium text-[#313E5A] text-center">
                acceptance by USCIS{" "}
              </p>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LastIsoSection;
