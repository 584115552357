import React from "react";
import CertOne from "assets/new-assets/iso_9001.webp";
import CertTwo from "assets/iso-standards/iso_17100.webp";
import CertThree from "assets/iso-standards/iso_18587.webp";
import Check from "assets/iso-standards/check.svg";
import isoOne from "assets/iso-standards/17100.webp";
import isoTwo from "assets/iso-standards/9001.webp";
import isoThree from "assets/iso-standards/18587.webp";
import ImageChi from "assets/iso-standards/image_1.webp";

const MidIsoSection = () => {
  return (
    <div className="bg-gradient-to-b from-white to-[#E8F1F5] py-16">
      <div className="w-10/12 2xl:w-8/12 flex flex-col justify-center mx-auto">
        <div className=" flex flex-col sm:w-9/12 sm:text-left text-center">
          <h2 className="text-[35px] font-secondary font-semibold">
            <span className="text-orange">ISO Standards</span> Provide Evidence
            of Quality of Translation
          </h2>
          <p className="text-[18px] font-sans text-[#00173A] leading-8 pt-[24px]">
            At Tomedes, we pride ourselves on being able to deliver high-quality
            language services that meet the needs of clients from all over the
            world. To this end, we have strived to improve our systems and hire
            the best talent.
          </p>
          <p className="text-[18px] font-sans text-[#00173A] leading-8 pt-[24px]">
            Which is why we are happy to say that our translation services are
            backed by ISO-certification. ISO is recognized as the highest
            authoritative non-government body when it comes to developing
            international standards. An ISO accreditation means that your
            company or organization meets stringent operational requirements
            required for global operations.
          </p>
          <p className="text-[18px] font-sans text-[#00173A] leading-8 pt-[24px]">
            {" "}
            We at Tomedes have ISO accreditation in three categories:
          </p>
        </div>
        <div className="w-full pt-[60px] sm:pt-[135px] sm:flex-none flex-col justify-center text-center sm:text-left items-center">
          <h2 className="text-[35px] font-secondary font-semibold sm:w-[579px] pt-[1rem]">
            In the Field of Translation, Tomedes Gives You
            <span className="text-orange">&nbsp;Endless Possibilities</span>
          </h2>
          <div className="flex justify-between items-center mt-[-4rem]">
            <div className="w-full sm:w-[579px] sm:pt-0 pt-[7rem]">
              <img src={isoTwo} alt="" className="sm:hidden inline-block" />
              <h3 className="text-[22px] font-secondary font-semibold text-[#00173A] pt-[4rem]">
                ISO 9001:2015 – Quality Management Systems
              </h3>
              <p className="pt-[2rem] text-[18px] font-sans text-[#00173A]">
                This ISO certification pertains to best practices in quality
                management across all industries.
              </p>
              <p className="pt-[2rem] text-[18px] font-sans text-[#00173A]">
                Our operations are consistent—we are able to provide results
                that are not only fully compliant with international
                regulations, but also rate highly in terms of customer
                satisfaction.
              </p>
            </div>
            <a
              target="_blank"
              className="md:p-5 md:w-[368px]"
              href="https://skins.tomedes.com/S3UploadedFiles/1681812516p0942804674_FFPDF-English-108179.pdf"
            >
              <img src={CertOne} alt="" className="hidden sm:inline-block" />
            </a>
          </div>
          <div className="flex justify-between pt-[5rem]">
            <div className="w-full sm:w-[579px]">
              <img src={isoOne} alt="" className="sm:hidden inline-block" />
              <h3 className="text-[22px] font-secondary font-semibold text-[#00173A] pt-[4rem]">
                ISO 17100:2015 – Translation Services
              </h3>
              <p className="pt-[2rem] text-[18px] font-sans text-[#00173A]">
                The gold standard when it comes to translation services.
                Companies aiming for this certification must be able to
                demonstrate consistently high-quality work, done by language
                professionals with bilingual proficiency, for a diverse range of
                sectors.
              </p>
              <p className="pt-[2rem] text-[18px] font-sans text-[#00173A]">
                Not all companies are able to meet the threshold for this ISO
                certification, and Tomedes is one of the few in the world to do
                so. We provide translation services that are considered
                best-in-class around the world, as a leader in the industry.
              </p>
            </div>
            <a
              target="_blank"
              href="https://skins.tomedes.com/S3UploadedFiles/1680501573ISO_17065.pdf"
              className="md:p-5 md:w-[368px]"
            >
              <img
                src={CertTwo}
                alt=""
                className="hidden sm:inline-block shadow-xl"
              />
            </a>
          </div>
          <div className="flex justify-between pt-[5rem]">
            <div className="w-full sm:w-[579px]">
              <img src={isoThree} alt="" className="sm:hidden inline-block" />
              <h3 className="text-[22px] font-secondary font-semibold text-[#00173A] pt-[4rem]">
                ISO 18587:2017 – Machine Translation Post-Editing{" "}
              </h3>
              <p className="pt-[2rem] text-[18px] font-sans text-[#00173A]">
                Machine translation is a tool that most language service
                providers employ in order to provide quick turnaround when it
                comes to translation projects in high-demand sectors. But this
                is a double-edged sword, and in the wrong hands it can mean that
                clients end up with output that is of significantly poor
                quality.
              </p>
              <p className="pt-[2rem] text-[18px] font-sans text-[#00173A]">
                With this ISO certification, we at Tomedes can assure you that
                our machine translation work is handled and post-edited by
                specialists, allowing you to reap the benefits without any
                compromise to quality.
              </p>
            </div>
            <a
              target="_blank"
              href="https://skins.tomedes.com/S3UploadedFiles/1680501594ISO_18587.pdf"
              className="md:p-5 md:w-[368px]"
            >
              <img
                src={CertThree}
                alt=""
                className="hidden sm:inline-block shadow-xl"
              />
            </a>
          </div>
          <div
            className="md:flex md:justify-between md:items-center md:gap-16 mt-[4rem]"
            // className="w-full flex justify-between sm:pt-[4rem]"
            // style="align-items: flex-end;"
          >
            <img
              src={ImageChi}
              alt=""
              className="hidden md:block md:w-1/2 w-full max-w-[425px]"
              // className="w-[409px] h-[453px]"
            />
            <div className="pt-[4rem] md:pt-0 md:w-1/2">
              <h2 className="text-[32px] font-semibold font-secondary text-[#00173A]">
                What You Can Expect From Us
              </h2>
              <ul className="pl-[8px] w-full text-left list-none">
                <li className="flex gap-x-8 pt-[2rem]">
                  <img src={Check} alt="" />
                  <p className="text-[18px] font-sans text-[#00173A]">
                    Quality translations with the human touch{" "}
                  </p>
                </li>
                <li className="flex gap-x-8 pt-[2rem]">
                  <img src={Check} alt="" />
                  <p className="text-[18px] font-sans text-[#00173A]">
                    Over 120 Languages and 950+ language pairs{" "}
                  </p>
                </li>{" "}
                <li className="flex gap-x-8 pt-[2rem]">
                  <img src={Check} alt="" />
                  <p className="text-[18px] font-sans text-[#00173A]">
                    Real-time interpretation for events, trade shows, seminars
                    and more{" "}
                  </p>
                </li>{" "}
                <li className="flex gap-x-8 pt-[2rem]">
                  <img src={Check} alt="" />
                  <p className="text-[18px] font-sans text-[#00173A]">
                    Quick results, within 24 hours{" "}
                  </p>
                </li>{" "}
                <li className="flex gap-x-8 pt-[2rem]">
                  <img src={Check} alt="" />
                  <p className="text-[18px] font-sans text-[#00173A]">
                    QA and LQA{" "}
                  </p>
                </li>{" "}
                <li className="flex gap-x-8 pt-[2rem]">
                  <img src={Check} alt="" />
                  <p className="text-[18px] font-sans text-[#00173A]">
                    One-Year Guarantee on all projects{" "}
                  </p>
                </li>{" "}
                <li className="flex gap-x-8 pt-[2rem]">
                  <img src={Check} alt="" />
                  <p className="text-[18px] font-sans text-[#00173A]">
                    Constant customer-centric focus and feedback{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MidIsoSection;
