import IsoStandard from "components/ISO_Standards";
import Layout from "components/layout";
import React from "react";
import SEO from "components/seo";

const index = () => {
  const title = "ISO Certifications | Tomedes";
  const description =
    "Three ISO certifications for enhanced translation quality. Click here to see our ISO certifications for all our translation solutions";
  const keywords = "ISO, certificates, iso, certificate";
  const slug = "/iso-certifications";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <IsoStandard />
    </Layout>
  );
};

export default index;
