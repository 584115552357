import React from "react";
import isoOne from "assets/iso-standards/17100.webp";
import isoTwo from "assets/iso-standards/9001.webp";
import isoThree from "assets/iso-standards/18587.webp";

const IsoBanner = () => {
  return (
    <div className="py-8 sm:py-40 px-5 bg-cover bg-no-repeat bg-isoBanner ">
      <div className="flex flex-wrap 2xl:w-7/12 mx-auto sm:justify-around 2xl:justify-between items-center">
        <div className="w-full sm:w-[515px] sm:pt-0 pt-[3rem]">
          <h1 className="text-[40px] font-secondary font-semibold text-white">
            Three ISO Certifications For Quality Translations Every Time
          </h1>
        </div>
        <div className="flex gap-x-6 sm:pt-0 pt-[4.75rem] sm:pb-0 pb-[1rem]">
          <div className="">
            <img src={isoTwo} alt="" />
            {/* <p className="text-[18px] font-secondary text-white text-center pt-2 font-medium">
              ISO 17100:2015
            </p> */}
          </div>
          <div className="">
            <img src={isoOne} alt="" />
            {/* <p className="text-[18px] font-secondary text-white text-center pt-2 font-medium">
              ISO 9001:2015
            </p> */}
          </div>
          <div className="">
            <img src={isoThree} alt="" />
            {/* <p className="text-[18px] font-secondary text-white text-center pt-2 font-medium">
              ISO 18587:2017
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsoBanner;
